<template>
  <div class="login h_100 flex flex-justify-center">
    <div class="inner_box">
      <div class="title t-center" style="margin-bottom: 20px;">
        <span v-if="ENV_TEST">[TEST] </span>蚂蚁助学管理后台
      </div>
      <el-form ref="form" :model="param" :rules="rules">
        <el-form-item prop="name">
          <el-input
            v-model="param.account"
            placeholder="用户名"
            prefix-icon="el-icon-user"
            @keydown.enter.native="login"
          />
        </el-form-item>
        <el-form-item prop="name">
          <el-input
            type="password"
            prefix-icon="el-icon-lock"
            v-model="param.password"
            placeholder="密码"
            show-password
            @keydown.enter.native="login"
          />
        </el-form-item>
        <el-button
          class="w_100"
          type="primary"
          :loading="loginLoading"
          @click="login"
          >登录</el-button
        >
      </el-form>
    </div>
  </div>
</template>
<script>
import { JSEncrypt } from 'jsencrypt'
import {Login} from '@/api/login'
import config from "@/utils/config.js";

export default {
  name: "Login",
  data() {
    return {
      ENV_TEST: config.ENV_TEST,

      param: {
        account: "",
        password: "",
      },
      rules: {
        account: [{ required: true, message: "请输入用户名", trigger: blur }],
        password: [{ required: true, message: "请输入密码", trigger: blur }]
      },
      loginLoading: false
    };
  },
  created() {},
  methods: {
    async login() {
      let pwd = this.param.password
      let mobile = this.param.account.replace(/\s*/g,"")
      if(!pwd || !mobile){
        this.$message.info('请填写账号密码')
        return
      }
      this.loginLoading = true
      const parame = {
        mobile: mobile,
        password: this.passwordEncryption(pwd)
      }
      const {code, data} = await Login(parame)
      if(code == 200){
        localStorage.setItem("token",data.token)
        localStorage.setItem("managerType",data.manager_type)
        localStorage.setItem("auth",JSON.stringify(data.auth_list))
        localStorage.setItem("username",data.username)
        this.loginLoading = false
        const toPath = decodeURIComponent(this.$route.query?.redirect || '/')
        this.$router.replace(toPath).then(() => {
          if (this.$route.name == 'login') {
            location.reload()
            this.$router.replace('/')
          }
        })
      } else {
        this.loginLoading = false
      }

    },
    passwordEncryption(passwordUser) {
      let publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDiHQgcc55lLY/s6cfF+teZ8chBskyTMWwo1ai6nWMxjZh1EijuaenXbJu9DsJy579DGlP2Y8+0cAfCZLYXbp8ab1XANbH4ubqkq5eV9drBbCAIvDZeCS65MaHg3zln/AbTIDxfVEbGcI/g3kRQF7X0Pp7YfABZ6o04SqRfaV8npwIDAQAB"
      let encryptor = new JSEncrypt() 
      encryptor.setPublicKey(publicKey)
      let passwordEncryp = encryptor.encrypt(passwordUser)
      return passwordEncryp
    },
  }
};
</script>

<style lang="scss" scoped>
.login {
  background-color: #f5f7f9;
  display: flex;
  align-items: center;
  justify-content: center;
  .inner_box {
    padding: 20px;
    width: 450px;
    background: #fff;
    .title {
      // padding-bottom: 40px;
      font-size: 18px;
    }
    /deep/ .el-form-item {
      // background-color: #283443;
      border: 1px solid #ccc;
      border-radius: 4px;
      .el-input {
        input {
          height: 20px;
          padding-right: 30px;
          font-size: 13px;
          background-color: transparent;
          border: none;
        }
      }
    }
  }
}
</style>
